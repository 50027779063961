import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import { useSetLoaded } from 'components/Loader';
// import { FollowCircleContext } from '../components/follow-circle/follow-circle-store';

export const DelayLink = (props) => {
  const history = useHistory();
  let timeout = null;
  const match = useRouteMatch(props.to.pathname || props.to);

  useEffect(() => {
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (e) => {
    const { replace, to, delay, onDelayStart, onDelayEnd } = props;
    if (match && match.isExact) return;
    clearTimeout(timeout);
    if (timeout) return;
    onDelayStart(e, to);
    if (e.defaultPrevented) return;
    e.preventDefault();

    timeout = setTimeout(() => {
      if (replace) {
        history.replace(to);
      } else {
        history.push(to);
      }

      onDelayEnd(e, to);
    }, delay);
  };

  const { onDelayEnd, onDelayStart, ...newProps } = props;
  return <Link {...newProps} onClick={handleClick} />;
};

DelayLink.propTypes = {
  delay: PropTypes.number,
  onDelayStart: PropTypes.func,
  onDelayEnd: PropTypes.func,
};

DelayLink.defaultProps = {
  delay: 0,
  onDelayStart: () => {},
  onDelayEnd: () => {},
};

export const DelayLinkTransition = memo(({ className, ...props }) => {
  const setLoaded = useSetLoaded(false, false);
  const match = useRouteMatch(props.to);

  const actualMatch = match
    ? match.path === '/' || match.path === '/lehre'
      ? match.isExact
      : match
    : false;

  return (
    <DelayLink
      {...props}
      className={`${className} ${actualMatch ? 'active' : ''}`}
      delay={props.delay || 500}
      onDelayStart={(e, to) => {
        setLoaded(false);
        props.onDelayStart(e, to);
      }}
    />
  );
});

DelayLinkTransition.defaultProps = {
  onDelayStart: () => {},
  onDelayEnd: () => {},
};
