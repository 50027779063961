import PropTypes from 'prop-types';
import React from 'react';

import SimpleLink from '../SimpleLink';

const createClassName = (props) => {
  // const arr = Object.values(props);
  return props.join(' ');
};

const Button = ({
  label,
  className,
  children,
  tag: Tag,
  variant,
  color,
  size,
  startIcon,
  endIcon,
  ...props
}) => {
  const combinedClassName = createClassName([variant, color, size]);
  const CustomTag = props.href ? SimpleLink : Tag;

  return (
    <CustomTag className={`btn ${combinedClassName} ${className}`} {...props}>
      <span>
        {startIcon} {label || children} {endIcon}
      </span>
    </CustomTag>
  );
};

export default Button;

Button.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  variant: PropTypes.oneOf(['contained', 'outlined']),
  color: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  onClick: PropTypes.func,
  href: PropTypes.string,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  tag: 'button',
  color: 'primary',
  size: 'medium',
  className: '',
};
