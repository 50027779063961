const GenerateApiUrls = function ({ domain, token, cockpitFolder }) {
  const DOMAIN = domain;

  const ROOT_URL = `/${cockpitFolder}`;
  const API_TOKEN = `?token=${token}`;

  // if domain is needed elsewhere
  this.DOMAIN = DOMAIN;
  this.collection = (name) =>
    `${DOMAIN}${ROOT_URL}/api/collections/get/${name}${API_TOKEN}`;
  this.singleton = (name) =>
    `${DOMAIN}${ROOT_URL}/api/singletons/get/${name}${API_TOKEN}`;
  this.image = (path, { w, h, m = 'thumbnail', q = 85 }) =>
    `${DOMAIN}/cockpit/api/image${API_TOKEN}&src=${DOMAIN}${path}&w=${w}&h=${h}&o=true&q=${q}&m=${m}`;
};

export const Cockpit = new GenerateApiUrls({
  cockpitFolder: 'cockpit',
  token: '58e5088c22530b812c5db60e84e1e6',
  domain:
    process.env.NODE_ENV === 'development'
      ? 'https://kanalfein.activated-web.com'
      : window.location.origin,
});
