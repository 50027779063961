import React, { Suspense, useEffect, useRef, useState } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

import Nav from './Nav';
import { Footer } from './Footer';
import FlyingButton from 'ui/molecules/FlyingButton';

// Lazy import page components
const Home = React.lazy(() => import('../pages/Home'));
const Impressum = React.lazy(() => import('../pages/Impressum'));
const Datenschutz = React.lazy(() => import('../pages/Datenschutz'));

// import('../scss/lazy.min.css');

const routes = [
  {
    exact: true,
    path: '/',
    component: Home,
  },
  {
    exact: true,
    path: '/impressum',
    component: Impressum,
  },
  {
    exact: true,
    path: '/datenschutz',
    component: Datenschutz,
  },
];

const MainRouter = () => {
  const location = useLocation();
  const [lastLocation, setLastLocation] = useState('');

  useEffect(() => {
    if (lastLocation?.pathname === location.pathname) return;
    setLastLocation(location);
  }, [location, lastLocation]);

  return (
    <React.Fragment>
      <Nav />
      <Suspense fallback={null}>
        <Switch>
          {routes.map(({ component: Component, exact, path }, i) => (
            <Route key={i} path={path} exact={exact}>
              <Component />
            </Route>
          ))}
        </Switch>
      </Suspense>
      <FlyingButton />
      <Footer />
    </React.Fragment>
  );
};

export default MainRouter;
