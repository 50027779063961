import React, { useState, createContext, useEffect, useContext } from 'react';
import { Loader } from './index';

const LoaderContext = createContext();
export const useLoaded = () => useContext(LoaderContext);

const SetLoaderContext = createContext();
export const useSetLoaded = (auto = true, reset = false) => {
  const setLoaded = useContext(SetLoaderContext);
  useEffect(() => {
    // if (reset) return () => setLoaded(false);
    if (!auto) return;
    setLoaded(true);

    // return () => setLoaded(false);
  }, [setLoaded, auto, reset]);

  return setLoaded;
};

export const LoaderContextProvider = ({ children }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (loaded) {
      document.body.classList.add('page-loaded');
    } else {
      document.body.classList.remove('page-loaded');
    }
  }, [loaded]);

  return (
    <SetLoaderContext.Provider value={setLoaded}>
      <LoaderContext.Provider value={loaded}>
        <Loader loaded={loaded} />
        {children}
      </LoaderContext.Provider>
    </SetLoaderContext.Provider>
  );
};
