import React from 'react';
import PropTypes from 'prop-types';

const Image = ({ src, alt, ...props }) => {
  return <img src={src} alt={alt} {...props} />;
};

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
};

const Video = ({ poster, src, type, sources, children, ...props }) => {
  const source = sources.map(({ src, type }, i) => {
    return <source key={i} src={src} type={type} />;
  });

  return (
    <video poster={poster} src={src} type={type} {...props}>
      {source || children}
    </video>
  );
};

Video.propTypes = {
  poster: PropTypes.string,
  src: PropTypes.string,
  type: PropTypes.string,
  sources: PropTypes.arrayOf(
    PropTypes.shape({ src: PropTypes.string, type: PropTypes.string }),
  ),
  children: PropTypes.node,
};

const Media = ({ tag: Tag, className, ...props }) => {
  const CustomTag = Tag === 'img' ? Image : Tag === 'video' ? Video : null;

  return <CustomTag className={`media ${className}`} {...props} />;
};

Media.propTypes = {
  className: PropTypes.string,
};

Media.defaultProps = {
  tag: 'img',
  src: '',
  className: '',
};

export default Media;
