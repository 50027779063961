import { useEffect } from 'react';
import { useLocation } from 'react-router';
import * as ReactGa from 'utils/ReactGA';
import { useCookiePopup } from './CookieContext';

export const ReactRouterGa = ({ children }) => {
  const { pathname } = useLocation();
  const { noticed, googleAnalytics } = useCookiePopup();

  useEffect(() => {
    if (!noticed || !googleAnalytics) return;
    ReactGa.pageview(pathname);
  }, [pathname, noticed, googleAnalytics]);

  return children || null;
};
