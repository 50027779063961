import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const SimpleLink = ({ href, to, children, text, ...props }) => {
  if (href) {
    return (
      <a href={href} {...props}>
        {text || children}
      </a>
    );
  }

  if (to) {
    return (
      <Link to={to} {...props}>
        {text || children}
      </Link>
    );
  }
};

export default SimpleLink;

SimpleLink.propTypes = {
  href: PropTypes.string,
  to: PropTypes.string,
  label: PropTypes.string,
};
