import './scss/index.min.css';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable';
import ResizeObserver from 'resize-observer-polyfill';
import objectFitImages from 'object-fit-images';
import 'intersection-observer';

import App from './App';

import Bowser from 'bowser';

import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import 'core-js/features/promise';
import './polyfill';
// import reportWebVitals from './reportWebVitals';

import { Suspense } from 'react';
const PolyfillLoader = React.lazy(() => import('./PolyfillLoader'));

const browser = Bowser.getParser(window.navigator.userAgent);
const isModernBrowser = browser.satisfies({
  chrome: '>65',
  edge: '>80',
  firefox: '>69',
  opera: '>70',
});

if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver;
}

objectFitImages();

ReactDOM.render(
  <Suspense fallback={null}>
    {/* if not normaly working browser do the polyfills */}
    {!isModernBrowser ? (
      <PolyfillLoader>
        <App />
      </PolyfillLoader>
    ) : (
      <App />
    )}
  </Suspense>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
